<template>
  <v-dialog v-model="parent.uploadOperatorDocumentsModal" max-width="600px" persistent scrollable>
    <v-card>
      <v-card-title class="text-h6 white--text px-2 py-1" :class="titleClass"
        >運営添付書類{{ `｜` + parent?.uploadingRow?.member_name_ja }}</v-card-title
      >
      <v-card class="mx-4 my-1 pt-2" outlined elevation="0">
        <v-row no-gutters>
          <v-col class="d-flex pb-0" cols="12" sm="3">
            <v-card-text class="text-start text-sm-end align-self-center">
              <v-btn
                small
                color="primary darken-1"
                style="float: right"
                @click="
                  $refs.operatorDocumentsRef.click();
                  $refs.operatorDocumentsRef.value = '';
                "
                >ファイル追加
              </v-btn>
              <input type="file" required style="display: none" multiple @change="test" ref="operatorDocumentsRef" />
            </v-card-text>
          </v-col>
          <v-col class="d-flex pb-0" cols="12" sm="9">
            <div
              v-if="operatorDocuments && operatorDocuments.length > 0"
              class="align-self-center mr-2"
              style="width: 100%"
            >
              <div
                v-for="(row, index) of operatorDocuments"
                :key="index"
                style="display: flex; flexdirection: row; height: 40px"
              >
                <div style="overflow: hidden; white-space: nowrap; flex-grow: 1; text-overflow: ellipsis">
                  <a v-if="row.key" @click="downloadFile({ key: row.key })" style="line-height: 35px">{{ row.name }}</a>
                  <span v-else style="line-height: 35px">{{ row.name }}</span>
                </div>
                <v-btn
                  dense
                  color="red darken-1"
                  :loading="uploadLoading"
                  @click="operatorDocuments.splice(index, 1)"
                  style="float: right"
                  >削除</v-btn
                >
              </div>
            </div>
            <div v-else class="align-self-center mr-2" style="width: 100%">
              <span style="line-height: 35px">ファイルアップロードされていません。</span>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card-actions class="d-flex justify-end">
        <v-btn dense color="primary darken-1" :loading="uploadLoading" @click="register"> OK </v-btn>
        <v-btn
          dense
          color="orange darken-1"
          text
          :loading="uploadLoading"
          :disabled="uploadLoading"
          @click="parent.uploadOperatorDocumentsModal = false"
        >
          cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    parent: {
      type: Object,
      default: Object,
      required: true,
    },
  },
  data() {
    return {
      operatorDocuments: [],
    };
  },
  methods: {
    ...mapActions({
      getAwsCredentials: 'api/getAwsCredentials',
      upload: 'common/upload',
      apiUpdatePacking: 'api/updateEachPackingInfo',
      downloadFile: 'api/downloadFile',
    }),
    // TODO: メソッド名を変更すべき
    test(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        this.operatorDocuments.push(e.target.files[i]);
      }
    },
    async register() {
      const credentials = await this.getAwsCredentials({
        type: 'upload-document',
      });
      await Promise.all(
        this.operatorDocuments.map(async (row) => {
          if (row && row.name && row.size) {
            await this.upload({
              s3Info: credentials,
              file: row,
              keyParams: [this.parent.uploadingRow.shipping_seq_number],
            }).then((result) => {
              row.key = result.Key;
              return Promise.resolve(row);
            });
          } else {
            return Promise.resolve(null);
          }
        }),
      );

      const updates = [
        this.parent.uploadingRow.shipping_seq_number,
        null,
        null,
        null,
        null,
        null,
        this.operatorDocuments.map((row) => {
          return {
            key: row.key,
            name: row.name,
          };
        }),
        null,
        this.user ? this.user.userName : 'SYSTEM',
        null,
        null,
        null,
        null,
        null,
        null,
      ];
      await this.apiUpdatePacking(updates);
      this.parent.uploadOperatorDocumentsModal = false;
    },
  },
  computed: {
    ...mapGetters({
      labels: 'defined/labels',
      error: 'ui/error',
      success: 'ui/success',
      uploadLoading: 'ui/uploadLoading',
      user: 'defined/user',
    }),
    titleClass() {
      return {
        red: this.error,
        primary: !this.error && !this.success,
        success: this.success,
      };
    },
  },
  watch: {
    'parent.uploadOperatorDocumentsModal'() {
      if (
        this.parent.uploadingRow.operator_documents === null ||
        !Array.isArray(this.parent.uploadingRow.operator_documents)
      ) {
        this.parent.uploadingRow.operator_documents = [];
      }
      this.operatorDocuments = this.parent.uploadingRow.operator_documents;
    },
  },
};
</script>
<style scoped>
div.v-card__text {
  white-space: pre-wrap;
}
</style>
